.App {
  text-align: center;
}
$primary-color: #1f56c4;

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body {
  background-color: whitesmoke;
}

.boxShadow {
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);
}
.gradientText {
  background: linear-gradient(45deg, #42d392, #647eff); /* Gradient colors */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; /* Firefox support */
  display: inline-block; /* Ensures gradient is only applied to text */
}
.gradient-text {
  background: linear-gradient(45deg, #42d392, #647eff); /* Gradient colors */
  -webkit-background-clip: text; /* Clip background for WebKit browsers */
  -webkit-text-fill-color: transparent; /* Transparent text for WebKit browsers */
  background-clip: text; /* Clip background for Firefox support */
  text-fill-color: transparent; /* Transparent text for additional compatibility */
  display: inline-block; /* Ensure the gradient is only applied to the text */
  font-weight: bold;
}
.cardWithHover{
  transition: all .3s ease-in-out;
}
.cardWithHover:hover {
transform: scale(1.001);
box-shadow:3px 4px 4px rgba(0, 0, 0, 0.1)
}